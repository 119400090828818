import React from "react"
import { Link, graphql } from "gatsby"
import Nav from "../../Components/Nav"
import SEO from "../../Components/SEO"
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import './index.scss'; // add some style if you want!
import Footer from "../../Components/Footer"

export default function Index({ data }) {
  const { edges: posts } = data.allMarkdownRemark
  return (
    <main>
      <SEO title="Funovus Blog Page" description={`Funovus is a game development studio based in the United States, with team members around the globe, embracing a remote-working, geo-diverse team and culture. Some of our positions are remote-friendly, and all positions provide visa sponsorship for international students in the U.S.`}/>
      <Nav />
      <div className="blogPage" style={{padding: '6rem 0', maxWidth: 1050, margin: '0 auto'}}>
        <h2>Blogs</h2>
        {posts
          .filter(post => post.node.frontmatter.title.length > 0)
          .map(({ node: post }) => {
            const image = getImage(post.frontmatter.thumbnail)
            return (
              <Link to={post.frontmatter.path}>
                <div className="blog-post-preview" key={post.id}>
                  <div className="blog-post-thumbnail">
                    <GatsbyImage
                      image={image}
                      alt={post.frontmatter.title}
                    />
                  </div>
                  <div className="blog-post-content" style={{display: 'flex'}}>
                  <h3>
                    {post.frontmatter.title}
                  </h3>
                  <h4> </h4>
                  <p>{post.excerpt}</p>
                  </div>
                </div>
              </Link>
            )
          })}
      </div>
      <Footer />
    </main>
  )
}

export const pageQuery = graphql`
  query IndexQuery {
    allMarkdownRemark(filter: {
      fileAbsolutePath: {
         regex: "/(posts)/"
      }
   }, sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          excerpt(pruneLength: 250)
          id
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            path
            thumbnail {
              childImageSharp {
                gatsbyImageData(
                  height: 125
                  width: 200
                )
              }
            }
          }
        }
      }
    }
  }
`